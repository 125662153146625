import { useEffect, useState } from 'react'
import {
  faBootstrap,
  faCss3,
  faGitAlt,
  faHtml5,
  faJsSquare,
  faReact,
} from '@fortawesome/free-brands-svg-icons'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './index.scss'

const About = () => {
  const [letterClass, setLetterClass] = useState('text-animate')

  useEffect(() => {
    setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000)
  }, [])

  return (
    <>
      <div className="container about-page">
        <div className="text-zone about">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['A', 'b', 'o', 'u', 't', ' ', 'm', 'e']}
              idx={15}
            />
          </h1>
          <p align="LEFT" className="aboutme">
            Frontend Developer with a background in Digital Marketing and
            Business Development. Passionate about creating seamless user
            experiences with clean and efficient code and turning complex
            concepts into elegant, user-friendly interfaces. I thrive on
            problem-solving and love collaborating with cross-functional teams
            to bring visions to life.
          </p>
          <p className="other">Other than that, I'm a:</p>
          <p className="ima2">
            <span className="ima">
              <span className="big">coffee lover.</span> dog mom. football
              enthusiast. <span className="big">reader.</span> adventure taker.
              wine drinker. <span className="big">beach lover.</span>
            </span>
          </p>
        </div>
        <div className="stage-cube-cont">
          <div className="cubespinner">
            <div className="face1">
              <FontAwesomeIcon icon={faBootstrap} color="#DD0031" />
            </div>
            <div className="face2">
              <FontAwesomeIcon icon={faHtml5} color="#F06529" />
            </div>
            <div className="face3">
              <FontAwesomeIcon icon={faCss3} color="#28A4D9" />
            </div>
            <div className="face4">
              <FontAwesomeIcon icon={faReact} color="#5ED4F4" />
            </div>
            <div className="face5">
              <FontAwesomeIcon icon={faJsSquare} color="#EFD81D" />
            </div>
            <div className="face6">
              <FontAwesomeIcon icon={faGitAlt} color="#EC4D28" />
            </div>
          </div>
        </div>
        <h2 className="skillsh2">Skills</h2>
        <div className="skills">
          <div className="skills__skill">React</div>
          <div className="skills__skill">TypeScript</div>
          <div className="skills__skill">Tailwind</div>
          <div className="skills__skill">Next</div>
          <div className="skills__skill">Remix</div>
          <div className="skills__skill">Git</div>
          <div className="skills__skill">Github</div>
          <div className="skills__skill">Responsive Design</div>
          <div className="skills__skill">SEO</div>
          <div className="skills__skill">Photoshop</div>
          <div className="skills__skill">Figma</div>
          <div className="skills__skill">SQL Basics</div>
        </div>
      </div>
      <Loader type="line-scale-pulse-out" />
    </>
  )
}

export default About
