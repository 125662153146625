import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Loader from 'react-loaders'
import AnimatedLetters from '../AnimatedLetters'
import logi4 from '../../assets/images/logi4.png'
import Logo from './Logo'
import './index.scss'

const Home = () => {
  const [letterClass, setLetterClass] = useState('text-animate')

  const nameArray = ['l', 'a', 'n', 'k', 'a']
  const jobArray = ['P', 'a', 'd', 'a', 'r']

  useEffect(() => {
    setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 4000)
  }, [])

  return (
    <>
      <div className="container home-page">
        <div className="text-zone">
          <h1>
            <span className={letterClass}>H</span>
            <span className={`${letterClass} _12`}>i,</span>
            <br />
            <span className={`${letterClass} _13`}>I</span>
            <span className={`${letterClass} _14`}>'m</span>
            <img
              className="logo"
              src={logi4}
              alt="JavaScript Developer Name, Web Developer Name"
            />
            <AnimatedLetters
              letterClass={letterClass}
              strArray={nameArray}
              idx={15}
            />
            <br />
            <AnimatedLetters
              letterClass={letterClass}
              strArray={jobArray}
              idx={22}
            />
          </h1>
          <div className="title">
            <h2>FrontEnd Developer</h2>
            <p>based in Copenhagen 🇩🇰</p>
          </div>
          <Link to="/portfolio" className="flat-button">
            Projects
          </Link>
          <Link to="/contact" className="flat-button">
            Contact
          </Link>
        </div>
        <Logo />
      </div>

      <Loader type="line-scale-pulse-out" />
    </>
  )
}

export default Home
