import { useEffect, useState } from 'react'
import Loader from 'react-loaders'
import { useRef } from 'react'
import emailjs from '@emailjs/browser'
import AnimatedLetters from '../AnimatedLetters'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import './index.scss'

const Contact = () => {
  const [letterClass, setLetterClass] = useState('text-animate')
  const form = useRef()

  useEffect(() => {
    setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000)
  }, [])

  const sendEmail = (e) => {
    e.preventDefault()

    emailjs
      .sendForm(
        'service_i54q7f3',
        'template_l85qaln',
        form.current,
        'tbOAFLSXa765D6FxX'
      )
      .then(
        () => {
          alert('Thanks for contacting me, I will get back to you ASAP 🥳')
          window.location.reload(false)
        },
        () => {
          alert('Something went wrong, please try again')
        }
      )
  }

  return (
    <>
      <div className="container contact-page">
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={['L', 'e', 't', "'", 's', '', 'T', 'a', 'l', 'k']}
              idx={15}
            />
          </h1>
          <p>
            Saw something you like? I'm always open to fun projects and
            collaborations. Say hi 👋🏼 or ask any questions and I'll be in touch
            shortly.
          </p>
          <div className="contact-form">
            <form ref={form} onSubmit={sendEmail}>
              <ul>
                <li className="half">
                  <input
                    placeholder="Name"
                    type="text"
                    name="user_name"
                    required
                  />
                </li>
                <li className="half">
                  <input
                    placeholder="Email"
                    type="email"
                    name="user_email"
                    required
                  />
                </li>
                <li>
                  <input
                    placeholder="Subject"
                    type="text"
                    name="subject"
                    required
                  />
                </li>
                <li>
                  <textarea
                    placeholder="Message"
                    name="message"
                    required
                  ></textarea>
                </li>
                <li>
                  <input type="submit" className="flat-button" value="SEND" />
                </li>
              </ul>
            </form>
          </div>
        </div>
        <div className="contact-wrap">
          <iframe
            title="myFrame"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d72034.30294919072!2d12.522271513279627!3d55.652957210634845!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4652535a5d084351%3A0x2fe2f273719b3dc8!2sK%C3%B8benhavn%20S%2C%202300%20Copenhagen!5e0!3m2!1sen!2sdk!4v1667823705272!5m2!1sen!2sdk"
            width="600"
            height="450"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
          <div className="contact-icons">
            <div>
              <a href="mailto:blanka.padrcom" className="link">
                <FontAwesomeIcon
                  className="contacticon"
                  icon={faEnvelope}
                  color="#181818"
                />{' '}
                <span className="contact">blanka.padr@gmail.com</span>
              </a>
            </div>
            <div>
              <a
                className="link"
                href="https://www.linkedin.com/in/blankapadar/"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  className="contacticon"
                  icon={faLinkedin}
                  color="#181818"
                />{' '}
                <span className="contact">Blanka Padar</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <Loader type="line-scale-pulse-out" />
    </>
  )
}

export default Contact
